const OwnerInfo = () => {
  return (
    <div className="d-flex justify-content-center py-4">
      <div className="py-4 px-4 col-xs-12 col-md-10 col-lg-9">
        <h1>Owner Maintenance Advice</h1>
        <p>
          1. Shower curtains are installed around most baths fitted with a
          shower head. These generally don’t work in rented properties. All
          baths should be fitted with a shower screen to stop leaks and related
          damage. Please look at the condition of the timber at low level at the
          tap end beside the bath. Failure to stop daily leaks from shower
          curtains comes under negligence and lack of maintenance and is not
          covered by any insurance.
        </p>
        <p>
          2. Most baths in the development are plastic and require good silicone
          seals between the walls and the bath to stop water leaks. These joints
          have to be sealed correctly and replaced every few years. This again
          is a maintenance issue for you. Please inspect your bathrooms
          regularly. All overflows and water outlets on sinks, basins and baths
          should be checked regularly to make sure they are not blocked. Please
          check all ball cocks.
        </p>
        <p>
          3. No baby wipes / facial wipes / sanitary towels / tampons are to be
          flushed down the toilets as blockages & leaks can cause a lot of
          damage to your own apartment and your neighbours for which the owner
          who causes it is liable.
        </p>
        <p>
          4. Please ensure that washing machines and dish washers are properly
          connected to avoid flooding. Rubber seals and hoses should be checked
          to make sure they are not perishing or leaking.
        </p>
        <p>
          5. Landlords and tenants regularly complain about condensation. This
          is caused by a) excessive drying of clothes, b) thermostat may be gone
          in cylinder, c) bathroom fan and kitchen extractor not working/not
          used, d) general ventilation caused by not opening or closing windows
          and overcrowding. Please check your apartment regularly to see that
          these conditions do not pertain.
        </p>
        <p>
          6. It is compulsory that all owners install a fire blanket in the
          kitchen and small fire extinguisher is recommended in your apartment.
          You are required to comply fully with S.I.17 OF 2017 if you are a
          landlord.
        </p>
        <p>
          7. Please advise your tenants not to put food waste / grease down the
          sink as this causes blockage in the pipes which eventually can lead to
          flooding in an apartment. We would suggest they use a strainer over
          the kitchen sink downpipe and flush regularly with boiling water.
        </p>
        <p>
          8. Please check that the filters in the kitchen extractor fans are
          replaced at least twice a year.
        </p>
        <p>
          9. We would advise all landlords to inform their new tenants of their
          existence of recycling bins, glass, paper, etc. and ask them not to
          leave any refuse in the hallways. Landlords regularly dump household
          furniture, beds etc. in the bin store. We will use CCTV to identify
          these people and bill them accordingly. When you buy a new appliance
          you are already covered for the re-cycling of the old appliance. All
          it takes is a little management to organise return on the same day, we
          would ask all landlords to do so.
        </p>
        <p>
          10. We would advise all landlords to advise all tenants that there is
          a large statutory fine for interfering with the fire alarm. These are
          there for the safety of all occupants and should be treated with
          respect. Should these activate, we will arrange for the fire alarm
          engineer to inspect your apartment.
        </p>
        <p>
          11. It is important that you have your own landlord contents insurance
          to cover all your contents including all your appliances, timber
          floors and basically anything you installed in your apartment
          yourself. Third party cover for damage you might cause your neighbours
          is also advisable. Remember insurance never covers maintenance issues
          or negligence.
        </p>
        <p>
          12. Stay on top of maintenance and repair needs and make repairs when
          requested. If the property is not kept in good repair, you’ll alienate
          good tenants.
        </p>

        <p>
          13. All Landlords must provide a copy of the house rules to their
          tenants as per your lease.
        </p>
        <p>
          14. All owners should be aware of the company’s safety statement.
          Owners are obliged to make anyone normally residing in their apartment
          aware of this safety statement.
        </p>
      </div>
    </div>
  );
};

export default OwnerInfo;
