import { Button } from "react-bootstrap";
import rules from "../assets/houserules.docx";

const HouseRules = () => {
  return (
    <div className="d-flex justify-content-center py-4">
      <div className="py-4 px-3 col-xs-12 col-md-10 col-lg-9">
        <div className="container pb-4">
          <div className="row">
            <div className="col">
              <h1>House Rules</h1>
            </div>
            <div className="col d-flex justify-content-end">
              <a href={rules} download>
                <Button variant="outline-dark">Download Document</Button>
              </a>
            </div>
          </div>
        </div>
        <div className="container pt-4">
          <div className="row pb-4">
            <p>
              1. All residents are required to ensure that all stereo, radio,
              television appliances, music (live and recorded) and noise in
              general is kept at level that will not disturb neighbours at all
              times and in particular no noise should be audible from an
              apartment between 10pm and 8am. Apartment owners are responsible
              for ensuring tenants abide by this condition in the leasehold
              agreement.
            </p>
            <p>
              2. To keep the main entrance and rear doors to each Block closed
              and locked at all times and to close all doors quietly.
            </p>
            <p>
              3. Residents should never let unknown people to follow them
              through the main door or any of the fob security doors.
            </p>
            <p>
              4. Suspicious activities and trespassing people should be reported
              immediately to Store Street Garda Station: 01-666 8000.
            </p>
            <p>
              5. The lifts are meant to transport people only. Do not use them
              to move furniture or any other heavy item.
            </p>
            <p>
              6. Residents are reminded that balconies are to be maintained as
              an aesthetic feature and are not to be used for storage or for
              drying or airing laundry.
            </p>
            <p>
              7. To be responsible for the appropriate behaviour of children and
              guests at all times while in the common areas of the complex.
              Resident will be held responsible for any damage caused by either
              children or guests in the common areas.
            </p>
            <p>
              8. All Residents are required to use strong refuse bags and to tie
              them close and dispose them in the designed area. Under no
              circumstances should rubbish be left either in the hallway or in
              the courtyard.
            </p>
            <p>
              9. Under no circumstances are bicycles to be wheeled through the
              building, in the lifts or locked to the handrail in the main
              entrance or along the corridors.
            </p>
            <p>
              10. Please ensure that materials such as motor spirit or gas
              cylinders are safely stored. Residents are advised to install a
              small all-purpose fire extinguisher and a smoke alarm in their
              apartment.
            </p>
            <p>
              11. All the common area have to be kept clean and unwanted
              correspondence and leaflets must not litter the floor near the
              mailboxes but be properly disposed.
            </p>
            <p>
              12. All the corridors, stairwells, lift and all common areas are
              Smoke Free Zone.
            </p>
            <p>
              13. Tenants should advise Property Manager’s Office with their
              names and phone numbers so that can be contacted in emergencies.
            </p>
            <p>
              14. It is the responsibility of each apartment owner to ensure
              that tenants are given a copy of and respect these House Rules.
            </p>
            <p>
              15. All vehicles using the car park must have current Insurance
              Disk, Tax Disk and NCT Disk if required.
            </p>
            <p>16. Short Stay lets (AirBnB) are not allowed in Jervis Place.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseRules;
