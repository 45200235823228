import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
const MyNav = (props) => {
  return (
    <Navbar
      collapseOnSelect
      bg="light"
      variant="light"
      expand="md"
      className="px-4 py-4"
    >
      <Button variant="light" onClick={() => props.setCurrent(1)}>
        <Navbar.Brand>Jervis Place</Navbar.Brand>
      </Button>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link onClick={() => props.setCurrent(1)}>Home</Nav.Link>
          <NavDropdown title="Useful Info" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => props.setCurrent(3)}>
              Safety Statement
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.setCurrent(4)}>
              House Rules
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.setCurrent(5)}>
              Fire Safety
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.setCurrent(6)}>
              Insurance Schedule
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.setCurrent(7)}>
              Owner Maintenance Advice
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.setCurrent(8)}>
              CCTV Policy
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={() => props.setCurrent(2)}>Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNav;
