const Contact = () => {
  return (
    <div className="d-flex justify-content-center py-4">
      <div className="py-4 px-4 col-xs-12 col-md-10 col-lg-9">
        <h1>Contact Information</h1>
        The Jervis Place Apartments are managed by the Piphees Parke Management
        Company.
        <p>The Managing Agent is Mr. Peter Sinclair.</p>
        <h5>
          Email:{" "}
          <a href="mailto:pipheesparke@hotmail.com">pipheesparke@hotmail.com</a>
        </h5>
        <h5>Phone:</h5>
        <p>
          Inquiries: <a href="tel:086-2401774">086-2401774</a>
        </p>
        <p>from 9 am – 5 pm Monday to Friday.</p>
        <p>
          Emergencies: <a href="tel:086-2401774">086-2401774</a>
        </p>
        <h5>Address:</h5>
        <p>72 Leinster Wood, Maynooth, Co. Kildare, W23 TOF2, Ireland</p>
      </div>
    </div>
  );
};

export default Contact;
