import { Button } from "react-bootstrap";

const Footer = (props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer">
      <div className="container">
        <div className="row pt-4">
          <div className="col-xs-12 col-md-6 px-4 py-4">
            <h3>Managing Agent's Contact Info</h3>
            Managing Agent: Peter Sinclair
            <h5>
              Email:{" "}
              <a href="mailto:pipheesparke@hotmail.com">
                pipheesparke@hotmail.com
              </a>
            </h5>
            <h5>Phone:</h5>
            <p>
              Inquiries: <a href="tel:086-2401774">086-2401774</a>
            </p>
            <p>from 9 am – 5 pm Monday to Friday.</p>
            <p>
              Emergencies: <a href="tel:086-2401774">086-2401774</a>
            </p>
            <h5>Address:</h5>
            <p>72 Leinster Wood, Maynooth, Co. Kildare, W23 T0F2, Ireland</p>
          </div>
          <div className="col-xs-12 col-md-6 px-4 py-4">
            <h3>Useful Links</h3>
            <div className="pb-2">
              <Button
                variant="light"
                onClick={() => {
                  scrollToTop();
                  props.setCurrent(3);
                }}
              >
                Safety Statement
              </Button>
            </div>
            <div className="pb-2">
              <Button
                variant="light"
                onClick={() => {
                  scrollToTop();
                  props.setCurrent(4);
                }}
              >
                House Rules
              </Button>
            </div>
            <div className="pb-2">
              <Button
                variant="light"
                onClick={() => {
                  scrollToTop();
                  props.setCurrent(5);
                }}
              >
                Fire Safety
              </Button>
            </div>
            <div className="pb-2">
              <Button
                variant="light"
                onClick={() => {
                  scrollToTop();
                  props.setCurrent(6);
                }}
              >
                Insurance Schedule
              </Button>
            </div>
            <div className="pb-2">
              <Button
                variant="light"
                onClick={() => {
                  scrollToTop();
                  props.setCurrent(7);
                }}
              >
                Owner Maintenance Advice
              </Button>
            </div>
            <div className="pb-2">
              <Button
                variant="light"
                onClick={() => {
                  scrollToTop();
                  props.setCurrent(8);
                }}
              >
                CCTV Policy
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
