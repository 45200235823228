import cctvpolicy from "../assets/cctv.pdf";
import { Button } from "react-bootstrap";

const CCTVPolicy = () => {
  return (
    <div className="d-flex justify-content-center py-4">
      <div className="py-4 px-3 col-xs-12 col-md-10 col-lg-9">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>CCTV Policy</h1>
            </div>
            <div className="col d-flex justify-content-end">
              <a href={cctvpolicy} download>
                <Button variant="outline-dark">Download Document</Button>
              </a>
            </div>
          </div>
        </div>

        <div className="container pt-4">
          <div className="row pb-4">
            <p className="pb-2">
              Piphees Parke Management Company takes the security and privacy of
              our residents very seriously. Please find our policy document on
              CCTV cameras in Jervis Place Apartments’ common areas below, along
              with the associated Data Protection Policy. Last updated February
              2018.
            </p>
            <object
              data={cctvpolicy}
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>Error displaying file, please use download button above</p>
            </object>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCTVPolicy;
