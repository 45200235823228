import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
import { useState } from "react";
import Safety from "./components/Safety";
import HouseRules from "./components/HouseRules";
import FireSafety from "./components/FireSafety";
import Insurance from "./components/Insurance";
import OwnerInfo from "./components/OwnerInfo";
import CCTVPolicy from "./components/CCTVPolicy";

function App() {
  const [current, setCurrent] = useState(1);
  return (
    <div className="d-flex flex-column">
      <Navbar setCurrent={setCurrent} />
      {current === 1 ? <Home /> : <></>}
      {current === 2 ? <ContactUs /> : <></>}
      {current === 3 ? <Safety /> : <></>}
      {current === 4 ? <HouseRules /> : <></>}
      {current === 5 ? <FireSafety /> : <></>}
      {current === 6 ? <Insurance /> : <></>}
      {current === 7 ? <OwnerInfo /> : <></>}
      {current === 8 ? <CCTVPolicy /> : <></>}
      <Footer setCurrent={setCurrent} />
    </div>
  );
}

export default App;
