import insurancesched from "../assets/insurance.pdf";
import { Button } from "react-bootstrap";

const Insurance = () => {
  return (
    <div className="d-flex justify-content-center py-4">
      <div className="py-4 px-3 col-xs-12 col-md-10 col-lg-9">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Insurance Schedule</h1>
            </div>
            <div className="col d-flex justify-content-end">
              <a href={insurancesched} download>
                <Button variant="outline-dark">Download Document</Button>
              </a>
            </div>
          </div>
        </div>
        <div className="container pt-4">
          <div className="row pb-4">
            <p className="pb-2">
              See the Property Insurance schedule for Jervis Place below. This
              document is updated annually.{" "}
            </p>
            <object
              data={insurancesched}
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>Error displaying file, please use download button above</p>
            </object>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
