import { Button } from "react-bootstrap";
import safetystatement from "../assets/safetystatement.pdf";

const Safety = () => {
  return (
    <div className="d-flex justify-content-center py-4">
      <div className="py-4 px-3 col-xs-12 col-md-10 col-lg-9">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Safety Statement</h1>
            </div>
            <div className="col d-flex justify-content-end">
              <a href={safetystatement} download>
                <Button variant="outline-dark">Download Document</Button>
              </a>
            </div>
          </div>
        </div>
        <div className="container pt-4">
          <div className="row pb-4">
            <h3>General Statement of Policy</h3>
            <p>
              It is policy of this company to comply with the Safety Health &
              Welfare at Work Act 2005, the Safety, Health and Welfare at Work
              (General Application) Regulations 2007 the Multi-Unit Developments
              Act 2011, Safety, Health & Welfare At Work (Construction)
              Regulations 2013 and to any other regulations which apply to our
              undertakings.
            </p>
            <p>
              The purpose of this Safety Statement is to identify hazards and
              assess risks and to specify control measures with a view to
              avoiding accidents & dangerous occurrences in the workplace.
            </p>
            <p>
              It is the intention of Piphees Parke Management to bring this
              Safety Statement to the attention of all sub-contractors. Any
              representations made by sub-contractors will be included in this
              document and in particular those made by sub-contractors where new
              hazards appear.
            </p>
            <p>
              The Safety Statement will be amended as necessary, where new
              hazards are identified and where work practices dictate.
            </p>
            <p>
              All sub-contractors must comply with the Policies and Procedures
              set out in the Safety Statement and to facilitate Piphees Parke
              Management in providing a safe place of work.
            </p>
            <p>
              See the full Safety Statement for Jervis Place below. Can’t view
              in your browser? Download the full safety statement using the
              button above.
            </p>
          </div>
          <object
            data={safetystatement}
            type="application/pdf"
            width="100%"
            height="600px"
          >
            <p>Error displaying file</p>
          </object>
        </div>
      </div>
    </div>
  );
};

export default Safety;
