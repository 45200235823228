import entrances from "../assets/entrances.png";

const Home = () => {
  return (
    <div>
      <div>
        <img className="picture" src={entrances} alt="" />
      </div>
      <div className="d-flex justify-content-center py-4 px-2">
        <div className="col-xs-12 col-md-10 col-lg-9">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-lg-4 px-4 py-4">
                <h3>Welcome</h3>
                <h5>About Jervis Place</h5>
                <p className="bold">
                  Jervis Place was built in 1998 and consists of 143 Apartments,
                  5 commercial units and 1 public house in 4 separate buildings
                  varying from 4 floors to 5 floors over a basement. The
                  development’s address is Jervis Place, Upper Abbey Street,
                  Dublin 1.
                </p>
                <h5>About Piphees Parke Management</h5>
                <p>
                  Piphees Parke Management Company Limited by Guarantee (CLG) is
                  the company that owns the buildings and is tasked with
                  managing the common areas and services throughout the
                  buildings. All members of the company have a lease agreement
                  with Piphees Parke Management CLG for their apartment and the
                  company is collectively owned by all the members of the
                  company.
                </p>
              </div>
              <div className="col-xs-12 col-lg-8 px-4 py-4">
                <h3>Noticeboard – Latest Announcements</h3>
                <p>No Current Announcements</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
