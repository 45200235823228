const FireSafety = () => {
  return (
    <div className="d-flex justify-content-center py-4">
      <div className="py-4 px-4 col-xs-12 col-md-10 col-lg-9">
        <h1>Fire Safety</h1>
        <p>
          All apartments must be equipped with the minimum fire safety equipment
          and information by law.
        </p>
        <p>
          The apartment owner, whether a resident or a landlord, is responsible
          for making sure their apartment complies fully with this law and that
          any residents who normally reside there are aware of these minimum
          standards. See the Dept of the Environments “minimum standards for
          rented apartments”. These include a fire blanket, mains wired smoke
          alarm and anemergency evacuation plan.
        </p>
        <h2>Fire Systems in the Building</h2>
        <p>
          The Fire protection systems in Jervis Place are as per the Fire
          Certificate granted to the builder back in 1996. We have added to
          these an LX3 common area alarm in the buildings to comply with S.I.17
          of 2017.
        </p>
        <h3>Apartments</h3>
        <p>
          Each apartment is a 1 hour fire compartment fitted with its own mains
          supplied, battery backed up, smoke alarm. This means that each
          apartment is designed to contain a fire for 1 hour before it can
          spread to another apartment. Within each apartment there is a 20
          minute fire protected entrance hall. The doors to the bedrooms and
          kitchen/living room are 20 minute fire doors and are fitted with door
          closers. These should be kept closed at all times. A fire in one room
          will be contained for 20 minutes allowing the occupants of the other
          rooms to vacate the apartment through the hall to the common areas and
          raise the alarm.
        </p>
        <h3>Common Areas in the building</h3>
        <p>
          Some of the common hallways have Manual Opening Vents and some have
          Automatic Opening Vents to vent any smoke from these escape routes.
          All common area hallways have fire doors with automatic door closers
          fitted to them. All common areas are fitted with fire extinguishers.
          There is no Common Area Alarm. Break glass units are fitted beside all
          locked doors to open doors in cases of emergency. People exiting the
          building in an emergency should press these as they pass.
        </p>
        <h3>Basement Car Park</h3>
        <p>
          The basement car park is fitted with a fire alarm made up of sounders
          and heat detectors. It is also fitted with fire extinguishers and
          water hose reels.
        </p>
        <p>
          Priority must be given to AVOIDANCE OF FIRE. Some simple steps like
          testing smoke alarms, making sure naked flames not near any flammable
          materials. Disconnect electrical appliances, hair dryers, curlers,
          televisions, music systems and chargers of any kind when not in use.
          Do not charge phones and computers on soft furnishing. (The latter was
          the cause of one fire in our building). Smokers make sure to crush out
          cigarette butts before putting them in the bin. Smokers throwing
          cigarette butts out windows put all residents at risk of fire. Keep
          candles away from curtains and make sure they are contained in stable,
          non flammable containers. Extractor hoods over cookers should be
          cleaned of grease regularly and water pumps and electric heaters
          should be checked.
        </p>
        <p>
          All residents should be prepared for dealing with a Fire. Residents
          should be familiar with where their FIRE BLANKET and EXTINGUISHER are
          kept and how to use them. All residents should be familiar with their
          escape route from the building to a safe location. These routes
          through the common areas are illustrated and hanging on the walls
          throughout the buildings.
        </p>
        <div className="note">
          <h2>A Note from Management</h2>
          <p>
            "In the past I have seen the results of three fires. One was started
            by a laptop computer left charging on a bed, another was from a hair
            dryer or curling tongs left plugged in. Both of these fires
            destroyed the bedrooms they were in. Luckily no one was injured
            other than smoke inhalation. The third one was from a water pump in
            a hot press. While this last one was a very small fire due to the
            quick reactions of the residents it does show you that fire can
            break out anywhere.
          </p>
          <p>
            Your apartment should be treated like a very expensive car. It needs
            a full service every year and as owners of these apartments you
            should make sure the people who are using it treat it right and know
            how to operate it."
          </p>
        </div>
      </div>
    </div>
  );
};

export default FireSafety;
